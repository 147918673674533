// @flow
import { css } from "styled-components";
import { media } from "../../media";
import {
  extraLargeHeading,
  largeHeading,
  regularHeading,
  smallHeading,
  sectionHeading,
} from "../Typography/Headings";
import { mediumParagraph } from "../Typography/Paragraphs";

// Based on hompage hero big heading
const H1Styles = css`
  margin: 10px 0 0;
  ${largeHeading}
  ${media.tablet`
    margin: 0 0 40px;
    ${extraLargeHeading}
  `}
`;

// Based on Half and Half slice title
const H2Styles = css`
  ${regularHeading}
  ${media.tablet`
    margin: 0 0 40px;
    ${largeHeading}
  `}
`;

// Based on 3 col CTA headings
const H3Styles = css`
  margin: 0 0 20px;
  ${regularHeading}
`;

// Based on Subtitle on home page
const H4Styles = css`
  margin: 10px 0 0;
  ${smallHeading}
`;

// Based on Heading labels
const H6Styles = css`
  margin: 10px 0 0;
  ${sectionHeading}
`;

// Based on Half and Half slice paragraphs
const PStyles = css`
  margin: 20px 0 0;
  ${mediumParagraph}
`;

const LiStyles = css`
  ${mediumParagraph}
`;

const AStyles = css`
  color: ${({ theme }) => theme.palette.hague};
  font-weight: 500;
  text-decoration: underline;
  font-size: inherit;
  line-height: inherit;
`;

const BStyles = css`
  font-weight: 500;
`;

export {
  H1Styles,
  H2Styles,
  H3Styles,
  H4Styles,
  H6Styles,
  PStyles,
  AStyles,
  BStyles,
  LiStyles,
};
