// @flow
import { cloneElement } from "react";
import styled, { css } from "styled-components";
import { isEmpty } from "lodash";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { media } from "../../media";
import {
  H1Styles,
  H2Styles,
  H3Styles,
  H4Styles,
  H6Styles,
  PStyles,
  AStyles,
  BStyles,
  LiStyles,
} from "./styles";
import { EmbeddedImage } from "../EmbeddedImage";

const centerStyles = css`
  * {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const standardStyles = css`
  text-align: center;
  ${media.tablet`
    text-align: left;
  `}
`;

const RichTextStyles = styled.div`
  ${({ center }) => (center ? centerStyles : standardStyles)}

  h1 {
    ${H1Styles};
  }
  h2 {
    ${H2Styles};
  }
  h3 {
    ${H3Styles};
  }
  h4 {
    ${H4Styles};
  }
  h6 {
    ${H6Styles};
  }
  p {
    ${PStyles};
  }
  a {
    ${AStyles};
  }
  li {
    ${LiStyles}
  }
  strong {
    ${BStyles};
  }

  img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

type Props = {
  children: PrismicRichText,
  center?: boolean,
  className?: string,
  white?: boolean,
};

const replaceEmbeddedImage = (node, index) => {
  // Image added by url, through 'embed' button
  if (node.props["data-oembed-provider"] === "static_image") {
    return <EmbeddedImage key={index} src={node.props["data-oembed"]} />;
  }
  // Image added from prismic image gallery, through 'image' button
  if (node.props.children && node.props.children.type === "img") {
    const { src, alt } = node.props.children.props;
    return <EmbeddedImage key={index} src={src} alt={alt} />;
  }
  return node;
};

/*
 * Replacing the images applies some default styles and sets widths
 * with CSS, ensuring we're rendering at maximum possible resolution
 * on retina screens.
 */
export const createResponsiveImages = (node: React$Element<any>) =>
  cloneElement(node, {}, node.props.children.map(replaceEmbeddedImage));

export const PlainText = ({ children }: Props) =>
  PrismicRichText.asText(children);

export const hasContent = (text: PrismicRichText) =>
  text?.length > 0 && !isEmpty(text[0]);

export const RichText = ({ children, className, center }: Props) =>
  hasContent(children) && (
    <RichTextStyles className={className} center={center}>
      {createResponsiveImages(PrismicRichText.render(children))}
    </RichTextStyles>
  );
