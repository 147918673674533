// @flow
import styled, { css } from "styled-components";
import queryString from "query-string";
import { ResponsiveImage } from "./ResponsiveImage";

export const defaultImageStyles = css`
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const FixedWidthResponsiveImage = styled(ResponsiveImage)`
  ${defaultImageStyles}
  width: ${({ width }) => width}px;
`;

type Props = {|
  src: string,
  alt?: string,
|};

export const EmbeddedImage = ({ src, ...rest }: Props) => {
  const { query } = queryString.parseUrl(src);
  if (query.w) {
    return <FixedWidthResponsiveImage {...rest} src={src} width={query.w} />;
  }
  return <ResponsiveImage css={defaultImageStyles} {...rest} src={src} />;
};
