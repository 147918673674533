// @flow
import { css } from "styled-components";
import { ButtonLink } from "@nested/component-library";
import { getImage } from "@nested/utils";
import { media } from "../../media";
import {
  regularHeading,
  largeHeading,
} from "../../components/Typography/Headings";
import { RichText } from "../../components/RichText/RichText";
import { smallParagraph } from "../../components/Typography/Paragraphs";
import { ResponsiveImage } from "../../components/ResponsiveImage";

const StraightDashes = () => (
  <svg width="2px" height="23px" viewBox="0 0 2 23">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.8"
      strokeDasharray="3,7"
    >
      <path d="M1,0 L1,30" stroke="#0A4254" strokeWidth="2" />
    </g>
  </svg>
);

const CurvedDashes = () => (
  <svg viewBox="0 0 1084 1048">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.8"
      strokeDasharray="3,7"
    >
      <path
        d="M1,0 L1,992 C1,1008.56854 14.4314575,1022 31,1022 L1053,1022 C1069.56854,1022 1083,1035.43146 1083,1052"
        stroke="#0A4254"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const mainHeadingStyle = css`
  text-align: center;
  padding: 0px 20px;
  margin: 40px 0px 0px;
  ${regularHeading}
  ${media.tablet`
    ${largeHeading}
    max-width: 534px;
    margin 40px auto 0 auto;
  `}
  ${media.desktop`
    max-width: 1140px;
    margin: 80px auto;
  `}
`;

const image = css`
  width: 100%;
  display: block;
  margin: auto;
  max-width: 534px;
  ${media.desktop`
    max-width: unset;
    width: 50%;
    box-sizing: border-box;
    padding: 0px 20px;
  `}
`;

const headingStyle = css`
  ${regularHeading}
  text-align: center;
  margin: 0px;
  ${media.tablet`
    text-align: left;
    ${largeHeading}
  `}
  ${media.desktop`
    margin-top: 20px;
  `}
`;

const wrapper = css`
  padding: 40px 20px;
  position: relative;
  ${({ first }) => (first ? "" : "padding-top: 50px;")}
  ${({ last }) => (last ? "" : "padding-bottom: 50px;")}
  ${media.desktop`
    padding: 80px 0px;
    ${({ first }) => (first ? "padding-top: 0px;" : "padding-top: 73px;")}
    ${({ last }) => (last ? "padding-bottom: 0px;" : "padding-bottom: 123px;")}
  `}
`;

const numberStyle = css`
  color: ${({ theme }) => theme.palette.terracotta80};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 14px;
  text-align: center;
  margin-top: 15px;
  ${media.tablet`
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 20px;
    padding-top: 10px;
    text-align: left;
  `}
`;

const topDashes = css`
  ${({ hide }) => (hide ? "display: none;" : "")}
  position: absolute;
  top: 0;
  left: 50%;
  ${media.desktop`
    display: none;
  `}
`;

const bottomDashes = css`
  ${({ hide }) => (hide ? "display: none;" : "")}
  position: absolute;
  bottom: 0;
  left: 50%;
  ${media.desktop`
    display: none;
  `}
`;

const contentWrapper = css`
  ${media.tablet`
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 20px;
    max-width: 534px;
  `};
  ${media.desktop`
    flex-direction: column;
    padding: 0px 20px;
    margin: 0;
  `}
`;

const horizontalWrapper = css`
  ${media.desktop`
    position: relative;
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    max-width: 1180px;
    margin: auto;
    align-items: center;
    overflow: hidden;
  `}
`;
const emptyColumn = css`
  display: none;
  ${media.desktop`
    display: block;
    width: calc(100% / 12);
    flex-grow: 0;
    flex-shrink: 0;
  `}
`;

const curvedDashesStyle = css`
  display: none;
  ${media.desktop`
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    max-width: 1085px;
    left: calc(100% / 24);
    right: calc(100% / 24);
    overflow: hidden;
    margin: auto;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      ${({ reverse }) => (reverse ? "transform: scale(-1, 1);" : "")}
    }
    ${({ last }) =>
      last &&
      css`
        bottom: unset;
        top: 0;
        svg {
          bottom: unset;
          top: 0;
        }
      `};
  `};
`;

const lineBlobs = css`
  display: none;
  ${media.desktop`
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    max-width: 1085px;
    left: calc(100% / 24);
    right: calc(100% / 24);
    margin: auto;
  `}
`;

const startBlob = css`
  position: absolute;
  top: 0;
  left: -7px;
  display: ${({ first }) => (first ? "block" : "none")};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.terracotta100};
`;

const endBlob = css`
  display: ${({ last }) => (last ? "block" : "none")};
  position: absolute;
  bottom: 0;
  ${({ reverse }) => (reverse ? "right: -7px" : "left: -7px;")};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.terracotta100};
`;

const outerWrapper = css`
  ${media.desktop`
    padding-bottom: 80px;
  `};
`;

const mainTextContainer = css`
  text-align: center;
  ${media.tablet`
    text-align: left;
  `}
`;

type Section = {
  image: PrismicImage,
  heading: string,
  buttonText?: string,
  buttonLink?: PrismicLink,
  richText: PrismicRichText,
  weListOnRightmoveAndZoopla?: "Yes" | "No",
};

type Props = {
  heading: String,
  repeat: Section[],
};

const rightmoveText = css`
  ${smallParagraph}
  font-size: 13px;
`;

const WeListOnRightmoveAndZoopla = () => (
  <p css={rightmoveText}>
    We list on
    <span>
      <ResponsiveImage
        src={getImage("partners/zoopla_logo_purple.png")}
        alt="Zoopla"
        css="width: 64px; height: 19px; vertical-align: middle;"
      />
    </span>
    and
    <span>
      <ResponsiveImage
        src={getImage("partners/rightmove_logo_navy_and_green.png")}
        alt="rightmove"
        css="width: 95px; height: 19px; vertical-align: sub; padding-left: 10px;"
      />
    </span>
  </p>
);

export const Timeline = ({ heading: mainHeading, repeat }: Props) => {
  const lastItemIndex = repeat.length - 1;
  return (
    <div css={outerWrapper}>
      <h3 css={mainHeadingStyle}>{mainHeading}</h3>
      {repeat.map(
        (
          {
            image: { url, alt },
            heading,
            buttonText,
            buttonLink,
            richText,
            weListOnRightmoveAndZoopla,
          },
          index,
        ) => (
          <DottedLineWrapper index={index} lastItemIndex={lastItemIndex}>
            <div css={horizontalWrapper} reverse={index % 2 === 1}>
              <div css={emptyColumn} />
              <img css={image} src={url} alt={alt} />
              <div css={contentWrapper}>
                <div css={numberStyle}>0{index + 1}</div>
                <div css={mainTextContainer}>
                  <h3 css={headingStyle}>{heading}</h3>
                  {richText && <RichText>{richText}</RichText>}
                  {weListOnRightmoveAndZoopla === "Yes" && (
                    <WeListOnRightmoveAndZoopla />
                  )}
                  {buttonText && buttonLink && (
                    <ButtonLink
                      to={buttonLink.url}
                      target={buttonLink?.target}
                      css={css`
                        margin-top: 30px;
                      `}
                    >
                      {buttonText}
                    </ButtonLink>
                  )}
                </div>
              </div>
            </div>
          </DottedLineWrapper>
        ),
      )}
    </div>
  );
};

const DottedLineWrapper = ({ children, index, lastItemIndex }) => (
  <div css={wrapper} first={index === 0} last={index === lastItemIndex}>
    <div
      css={curvedDashesStyle}
      last={index === lastItemIndex}
      reverse={index % 2 === 1}
    >
      <CurvedDashes />
    </div>
    <div css={lineBlobs}>
      <div css={startBlob} first={index === 0} />
      <div
        css={endBlob}
        last={index === lastItemIndex}
        reverse={index % 2 === 1}
      />
    </div>
    <div css={topDashes} hide={index === 0}>
      <StraightDashes />
    </div>
    {children}
    <div css={bottomDashes} hide={index === lastItemIndex}>
      <StraightDashes />
    </div>
  </div>
);
